import events from "../assets/img/3postersmockup2.webp";
import "../styles/eventsContainer.scss";

const EventsContainer = () => {
  return (
    <>
      <section id="events" className="section-text section-flow ">
        {/* Events */}
        <div className="columns">
          <div className="column is-5">
            <h1 className="title">Events</h1>
          </div>
          <div className="column content">
            <p>
              Join us and discover the untold narrative of sand in this full
              immersive experience at the following events.
            </p>
          </div>
        </div>
      </section>
      <div className="img-events-container">
        {/* <a
          href="https://www.instagram.com/murmur.noord/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="event-overlay event-overlay-1"></div>
        </a>

        <a
          href="https://strp.nl/nl/program/last-grain-of-sand"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="event-overlay event-overlay-2"></div>
        </a>

        <a
          href="https://www.fiber-space.nl/festival/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="event-overlay event-overlay-3"></div>
        </a> */}
        <img src={events} alt="" />
      </div>

    </>
  );
};

export default EventsContainer;
