import frameLeft from "../assets/img/frame/grainframe_1.png";
import frameRight from "../assets/img/frame/grainframe_2.png";

const Frame = () => {
  return (
    <div>
      <div className="frame">
        <img data-scroll data-scroll-speed="2" src={frameLeft} alt="Frame left" />
        <img
          className="frame-right"
          data-scroll
          data-scroll-speed="2"
          src={frameRight}
          alt="Frame right"
        />
      </div>
    </div>
  );
};

export default Frame;
