import "../../styles/modal.scss";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { PulseLoader } from "react-spinners";
import { AiOutlineWarning } from "react-icons/ai";
import CurrencyInput from "react-currency-input-field";

const TestModal = ({ grain, close }) => {
  const [currentFrame, setCurrentFrame] = useState(1);
  const { scroll } = useLocomotiveScroll();

  const Frame1 = () => {
    return (
      <>
        <div className="grain-img-container">
          <img src={grain.imgUrl} alt="Selected grain of sand." />
        </div>
        <p style={{ textAlign: "center" }}>
          Choose your unique sand grain and be part of the narrative of sand!
        </p>
        <button className="button" onClick={() => setCurrentFrame(2)}>
          Collect this grain
        </button>
      </>
    );
  };

  const Frame2 = () => {
    return (
      <>
        <Form grainId={grain.id} />
      </>
    );
  };

  const Form = ({ grainId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const { register, handleSubmit, getValues } = useForm();

    const onSubmitFree = (e) => {
      e.stopPropagation();
      if (isLoading) {
        return;
      }
      let hasError = false;
      const data = getValues();
      data.amount = "0.00";

      const foundErrors = [];

      if (!data) {
        hasError = true;
      }

      if (!data.terms) {
        hasError = true;
        foundErrors.push("Please agree to the privacy policy.");
      }

      if (data.name === "") {
        hasError = true;
        foundErrors.push("Please provide a name.");
      }

      if (data.email === "" || !validateEmail(data.email)) {
        hasError = true;
        foundErrors.push("Please provide a valid email.");
      }

      if (hasError) {
        setErrors(foundErrors);
        console.log(foundErrors);
        return;
      }

      onSubmit(data);
    };

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const onSubmit = (data) => {
      if (isLoading) {
        return;
      }

      const foundErrors = [];

      // Disabled donations
      // data.hasCustomAmount = document.getElementById("hasCustomAmount").checked;

      // if (data.hasCustomAmount) {
      //   data.amount = data.customAmount.substring(1).replace(".", "");

      //   const amountAsNumber = +data.amount.replace(",", ".");

      //   if (amountAsNumber < 1) {
      //     foundErrors.push("Custom amount needs to be higher than €1,00");
      //     setErrors(foundErrors);
      //     return;
      //   } else {
      //     setErrors([]);
      //   }
      // }

      const amount = data.amount ? data.amount : "0.00";

      const body = {
        name: data.name,
        email: data.email,
        grainId: grainId,
        amount: (+amount.replace(",", ".")).toFixed(2).toString(),
        subscribed: data.subscribe,
      };

      setIsLoading(true);

      axios
        .post(
          `https://us-central1-last-grain-of-sand-prod.cloudfunctions.net/payment`,
          body
        )
        .then((res) => {
          if (res.status === 201) {
            window.location.assign(res.data.url);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setCurrentFrame(3);
        });
    };

    const unselectRadioButtons = (e) => {
      const radio = document.querySelector("input[type=radio]:checked");
      if (radio && radio.id !== "hasCustomAmount") {
        radio.checked = false;
      }
    };

    const unselectCustomAmount = () => {
      const radio = document.getElementById("hasCustomAmount");

      if (radio) {
        radio.checked = false;
      }
    };

    return (
      <>
        <p>
          Please fill in your details here. We will email your selected grain to
          you.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-container">
            <label htmlFor="name">Your name</label>
            <input
              type="text"
              placeholder="Name"
              {...register("name", { required: true })}
              id="name"
            />
          </div>
          <div className="input-container">
            <label htmlFor="email">Your email</label>
            <input
              type="email"
              placeholder="Email"
              {...register("email", { required: true })}
              id="email"
            />
          </div>

          {/* <p className="label has-text-centered mt-0 mb-0">
            Choose your amount:
          </p>
          <div className="justify-center-container">
            <div className="radio-group">
              <input
                type="radio"
                id="option-one"
                name="selector"
                {...register("amount")}
                value="5.00"
                onClick={unselectCustomAmount}
              />
              <label className="label" htmlFor="option-one">
                €5.00
              </label>
              <input
                type="radio"
                id="option-two"
                name="selector"
                {...register("amount")}
                value="10.00"
                onClick={unselectCustomAmount}
              />
              <label className="label" htmlFor="option-two">
                €10.00
              </label>
              <input
                type="radio"
                id="option-three"
                name="selector"
                {...register("amount")}
                value="20.00"
                onClick={unselectCustomAmount}
              />
              <label className="label" htmlFor="option-three">
                €20.00
              </label>
            </div>
          </div> */}

          {/* <div className="mt-4">
            <input
              className="radio-custom"
              type="radio"
              placeholder="hasCustomAmount"
              id="hasCustomAmount"
              {...register("hasCustomAmount")}
              onClick={unselectRadioButtons}
            />
            <label htmlFor="hasCustomAmount">
              I want to choose my own amount
            </label>
            <CurrencyInput
              className="ml-2"
              min={1}
              allowNegativeValue={false}
              decimalScale={2}
              placeholder={"€..."}
              prefix="€"
              {...register("customAmount", { min: 1 })}
            />
          </div> */}

          <br />
          <div>
            <input
              type="checkbox"
              placeholder="terms"
              id="terms"
              {...register("terms", { required: true })}
            />
            <label htmlFor="terms">
              I agree to the{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>{" "}
              and{" "}
              <a
                href="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </a>
              .
            </label>
          </div>

          <div>
            <input
              type="checkbox"
              placeholder="subscribe"
              id="subscribe"
              {...register("subscribe")}
            />
            <label htmlFor="subscribe">
              I would like to receive emails on future updates in the Post Now
              series.
            </label>
          </div>

          <div className="buttons-container">
            <div>
              {isLoading && (
                <PulseLoader color={"#FF5028"} loading={true} size={15} />
              )}
            </div>

            {!isLoading && (
              <a className="button mt-0" onClick={onSubmitFree}>
                Claim grain
              </a>
            )}
          </div>
          <div className="errors-container">
            {errors.map((err, i) => (
              <span className="error-message" key={i}>
                <AiOutlineWarning /> {err}
              </span>
            ))}
          </div>
        </form>
      </>
    );
  };

  const ErrorFrame = () => {
    return (
      <>
        <p>
          Something went wrong trying to process your request. Please try again
          later.
        </p>
      </>
    );
  };

  const Frame = () => {
    switch (currentFrame) {
      case 1:
        return <Frame1 />;

      case 2:
        return <Frame2 />;

      case 3:
        return <ErrorFrame />;

      default:
        break;
    }
  };

  const closeModal = async () => {
    // gsap.default.to(".grain-modal", { autoAlpha: 0, duration: 0.1 });
    // await gsap.default.to(".modal-bg", { autoAlpha: 0, duration: 0.6 });

    close();
    setCurrentFrame(1);
    scroll.start();
  };
  return (
    <div className={grain ? "modal-test active" : "modal-test "}>
      <div className="modal-content gray-modal">
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <div
          style={{ flexDirection: "column", alignItems: "center", maxWidth: '500px' }}
          className="justify-center-container"
        >
          {grain && <Frame />}
        </div>
      </div>
    </div>
  );
};

export default TestModal;
