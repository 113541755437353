import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivacyPolicyContainer from "./containers/PrivacyPolicyContainer";
import CookiePolicyContainer from "./containers/CookiePolicyContainer";
import TermsAndConditionsContainer from "./containers/TermsAndConditionsContainer";
import Footer from "./components/Footer";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="privacy-policy" element={<PrivacyPolicyContainer />} />
        <Route path="cookie-policy" element={<CookiePolicyContainer />} />
        <Route path="terms-and-conditions" element={<TermsAndConditionsContainer />} />
      </Routes>
    </BrowserRouter>
    <Footer />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
