import "../styles/footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="columns is-flex-wrap-wrap">
        <div className="column is-4 footer-column">
          <ul>
            <li>Overhoeksplein 2</li>
            <li>1031 KS AMSTERDAM</li>
            <li>Netherlands</li>
            <li>0031 6 518 58 177</li>
          </ul>
        </div>
        <div className="column is-4 footer-column">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/Post-Neon-103952104424997/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/post.neon/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.behance.net/postneon"
                target="_blank"
                rel="noopener noreferrer"
              >
                Behance
              </a>
            </li>
          </ul>
        </div>
        <div className="column is-4 footer-column">
          <ul>
            <li>Business inqruiries</li>
            <li>
              <a href="mailto:info@post-neon.com">info@post-neon.com</a>
            </li>
            <li>
              <a
                href="https://post-neon.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                post-neon.com
              </a>
            </li>
          </ul>
        </div>
        <div className="column is-12">
          <p className="has-text-centered">
            <span className="quote">©</span>2022 - KvK: 73662550
            <br />
            <a
              className="has-text-centered"
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy policy
            </a>{" "}
            |{" "}
            <a
              className="has-text-centered"
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
