

const CreditColumn = ({ title, name, link = '' }) => {
  return (
    <div className="column is-4 credit-column">
      <a href={link} target="_blank" rel="noopener noreferrer">
      <h2>{title}</h2>
      <hr />
      <p>{name}</p>
    </a>
    </div>
  );
};

export default CreditColumn;
