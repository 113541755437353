import "../styles/menu.scss";
import { BiMenu } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { useEffect, useState } from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";

const Menu = ({ isIntro, isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAtTop, setIsAtTop] = useState(false);
  const { scroll } = useLocomotiveScroll();

  useEffect(() => {
    if (scroll) {
      scroll.on("scroll", (e) => {
        if (e.delta.y === 0) {
          setIsAtTop(true);
        } else {
          setIsAtTop(false);
        }
      });
    }
  }, [scroll]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const goToSection = (section) => {
    setIsOpen(false);

    const element = document.getElementById(section);
    scroll.scrollTo(element);
  };

  return (
    <>
      <nav
        className={
          !isIntro && !isAtTop && !isDisabled
            ? "mobile-nav enabled"
            : "mobile-nav"
        }
      >
        <button className="toggle" onClick={toggleMenu}>
          {!isOpen && <BiMenu />}
          {isOpen && <IoMdClose />}
        </button>
        <div className={isOpen ? "mobile-menu active" : "mobile-menu"}>
          <a onClick={() => goToSection("image")}>Claim</a>
          <a onClick={() => goToSection("about")}>About</a>
          <a onClick={() => goToSection("vr")}>VR</a>
          <a onClick={() => goToSection("grains")}>Grains</a>
          <a onClick={() => goToSection("events")}>Events</a>
        </div>
      </nav>

      <nav
        className={!isIntro && !isAtTop ? "desktop-nav enabled" : "desktop-nav"}
      >
        <div className="desktop-menu">
          <a onClick={() => goToSection("image")}>Claim</a>
          <a onClick={() => goToSection("about")}>About</a>
          <a onClick={() => goToSection("vr")}>VR</a>
          <a onClick={() => goToSection("grains")}>Grains</a>
          <a onClick={() => goToSection("events")}>Events</a>
        </div>
      </nav>
    </>
  );
};

export default Menu;
