import "../../styles/modal.scss";
import { useEffect } from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import * as gsap from "gsap";

const IntroModal = ({ close }) => {
  const { scroll } = useLocomotiveScroll();

  useEffect(() => {
    if (scroll) {
      scroll.stop();
    }
  }, [scroll]);

  const closeModal = async () => {
    gsap.default.to(".intro-modal", {
      autoAlpha: 0,
      filter: "blur(40px)",
      duration: 0.8,
    });
    gsap.default.to(".intro-logo-container", {
      autoAlpha: 0,
      filter: "blur(40px)",
      duration: 0.8,
    });
    await gsap.default.to(".modal-bg", { autoAlpha: 0, duration: 0.8 });

    close();
    scroll.start();
  };

  return (
    <>
      <div className="modal-bg active blur-mobile"></div>
      <div className="intro-logo-container">
        <h1 className="logo">Last Grain of Sand</h1>
        <p className="subtitle">
          A Virtual Exploration of the Scarcity of Sand.{" "}
        </p>
      </div>
      <div className="intro-modal-container">
        <div className="intro-modal">
          <blockquote>
            <span className="quote">“</span>Remove from our lives everything
            that depends on sand and our world would look very different. We
            would have no glass, no computer chips, we would have no buildings.
            We're just not aware of how important sand is to our daily lives.
            <span className="quote">”</span>
          </blockquote>
          <p className="author">Michael Welland (1946 - 2017)</p>
          <div className="justify-center-container">
            <button
              className="button inversed margin-bottom-2"
              onClick={() => closeModal()}
            >
              Enter
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroModal;
