import "../styles/michaelContainer.scss";
import sand from "../assets/img/michael/micheal_welland_sand.jpg";

const MichaelContainer = () => {
  return (
    <>
      {/* Michael Welland */}
      <section id="michael" className="section-text">
        <div className="columns is-flex-wrap-wrap">
          <div className="column is-5">
            <h1 className="title">Michael Welland</h1>
          </div>
          <div className="column is-7 content">
            <p>
              This project is dedicated to Michael Welland (1946 - 2017), a
              British Geologist and author of the award winning book{" "}
              <span className="quote">“</span>
              Sand a journey through Science and the imagination
              <span className="quote">”</span>. His profound and poetic
              understanding of life and the natural world of sand as
              demonstrated in his{" "}
              <a
                href="https://ateliernl.com/lectures/the-abundance-and-scarcity-of-sand"
                target="_blank"
                rel="noopener noreferrer"
              >
                final lecture at the symposium Abundance and Scarcity of Sand
              </a>{" "}
              (premiered by Atelier NL and MU Hybrid Arthouse) lies at the
              foundation of this project. Michael Welland recorded this last
              lecture whilst on his death bed. His utter devotion and dedication
              to raising awareness about the scarcity of sand can also be found
              in the documentary Sand Wars. We thank{" "}
              <a
                href="https://www.ateliernl.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Atelier NL
              </a>{" "}
              and{" "}
              <a
                href="https://www.mu.nl/en"
                target="_blank"
                rel="noopener noreferrer"
              >
                MU Hybrid Arthouse
              </a>{" "}
              for helping us share Welland's life's work and message.
            </p>

            <div className="justify-center-container">
              <a
                style={{ textAlign: "center" }}
                href="https://www.bol.com/nl/nl/p/sand/9200000016395096"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="embedded-image"
                  src={sand}
                  alt="'Sand, the never-ending story' by Michael Wellands"
                />
              </a>
            </div>
          </div>

          <div className="column is-5">
            <h2 className="title smaller-title">Post Now Series</h2>
          </div>

          <div className="column is-7 content">
            <p style={{ marginTop: "0em" }}>
              As a creative studio Post Neon aims to narrate unknown social
              topics through immersive storytelling. Its Post Now Series sets
              out to explore the future of complex realities that govern our
              planet. Through digital experiences that bridge the physical and
              non-physical world, seemingly abstract topics are brought within
              arms reach, providing us with shared insights that are essential
              to our current times. The Post Now Series will act as an ongoing
              framework that experiments with new design methodologies, new
              forms of collaborations and investigates innovative technologies
              whilst pushing the boundaries of immersive journalism. Together
              with its partners{" "}
              <a
                href="https://www.ateliernl.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Atelier NL
              </a>
              ,{" "}
              <a
                href="https://www.fiber-space.nl/festival/"
                target="_blank"
                rel="noopener noreferrer"
              >
                FIBER
              </a>
              ,{" "}
              <a
                href="https://www.mu.nl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                MU Hybrid Arthouse
              </a>{" "}
              and{" "}
              <a
                href="https://strp.nl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                STRP
              </a>{" "}
              , and affiliate creatives such as Charlotte Rohde, Pim Boreel,
              Niels Moolenaar, Arno Hoogland, Paul Evers, Matthijs Loriaux,
              Hannes Brischke, Ali Eslami, Xiaoyuan Goa, and Sabina Soctanu they
              are pushing the immersive storytelling format into international
              channels.
            </p>
          </div>

          <div className="column is-5">
            <h2 className="title smaller-title">Post Neon</h2>
          </div>

          <div className="column content">
            <p style={{ marginTop: "0em" }}>
              <a
                href="https://post-neon.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Post Neon
              </a>{" "}
              is a new creative studio based in Amsterdam creating digital
              experiences, physical installations, videos and 3D content. They
              explore alternate realities which bridge the physical and
              non-physical space. These alternate worlds allow Post Neon to
              experiment with scale, space and perception to enhance these
              immersive experiences. They have worked with institutions such as
              STRP, MU Artspace, Cinekid, TUE Innovation Space and brands such
              as Iris van Herpen, TopNotch and Adidas.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default MichaelContainer;
