import React from "react";

const Tile = React.memo( ({ grain, imageName, selectGrain }) => {
    return (
      <div
        className={`grid-tile ${grain?.isIssued ? "is-issued" : ""}`}
        onClick={!grain?.isIssued ? () => selectGrain(grain.grainId) : undefined}
      >
        <img src={require(`../../assets/img/tiles/${imageName}.jpg`)} />
      </div>
    );
  });

export default Tile