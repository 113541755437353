import { useEffect, useRef, useState } from "react";
import "../src/styles/global.scss";
import "../src/styles/cookies.scss";
import ImageSection from "./components/images/ImageSection";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db, app } from "./services/firebase-config";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import GrainsContainer from "./containers/GrainsContainer";
import Frame from "./components/Frame";
import AboutContainer from "./containers/MichaelContainer";
import EventsContainer from "./containers/EventsContainer";
import PartnersContainer from "./containers/PartnersContainer";
import Footer from "./components/Footer";
import Menu from "./components/Menu";
import Logo from "./components/Logo";
import IntroContainer from "./containers/IntroContainer";
import VrContainer from "./containers/VrContainer";
import IntroModal from "./components/modals/IntroModal";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { getAnalytics } from "firebase/analytics";
import RedirectModal from "./components/modals/RedirectModal";
import Carousel from "./components/Carousel";
import TestModal from "./components/modals/TestModal";
import CreditsContainer from "./containers/CreditsContainer";

function App() {
  const [selectedGrain, setSelectedGrain] = useState(null);
  const [allGrains, setAllGrains] = useState([]);
  const [isIntro, setIsIntro] = useState(true);
  const [isRedirected, setIsRedirected] = useState(false);
  const [isMenuDisabled, setIsMenuDisabled] = useState(false)
  const containerRef = useRef(null);

  let unsubscribe;

  const listenToGrains = async () => {
    unsubscribe = onSnapshot(doc(db, "meta", "grains"), (docSnap) => {
      if (docSnap.exists()) {
        const grains = docSnap.data().grains;
        setAllGrains(grains);
      }
    });
  };

  const requirePassword = () => {
    let hasCorrectPassword = false;

    while (!hasCorrectPassword) {
      const popup = prompt("Please enter password:", "");
      if (popup == null || popup == "") {
        console.log("cancelled");
      } else if (popup === "grainTest2022") {
        hasCorrectPassword = true;
        console.log("correct password!");
      } else {
        console.log("incorrect password!");
      }
    }
  };

  useEffect(() => {
    const hasConsent = getCookieConsentValue("analyticsEnabled");
    if (hasConsent) {
      enableAnalytics();
    }

    // requirePassword();
    listenToGrains();

    const params = new URLSearchParams(window.location.search);
    setIsRedirected(params.get("redirected") === "true");

    return unsubscribe;
  }, []);

  const openModal = async (grainId) => {
    const docSnap = await getDoc(doc(db, "grains", grainId));
    if (!docSnap.exists()) {
      return;
    }

    const grain = docSnap.data();

    setIsMenuDisabled(true);
    setSelectedGrain(grain);
  };

  const closeModal = () => {
    setIsMenuDisabled(false);
    setSelectedGrain(null);
  };

  const enableAnalytics = () => {
    getAnalytics(app);
  };

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept all cookies"
        cookieName="analyticsEnabled"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={730}
        onAccept={enableAnalytics}
        enableDeclineButton
        declineButtonText="Only essential cookies"
        declineButtonClasses="cookie-decline-button"
      >
        This website uses cookies to enhance the user experience and for analytical purposes. <a style={{ textDecoration: 'underline' }} href="/cookie-policy" target="_blank" rel="noopener noreferrer">Learn more about our cookie policy</a>.
      </CookieConsent>
      <LocomotiveScrollProvider
        options={{
          smooth: true,
          mobile: {
            breakpoint: 0,
            smooth: true,
            getDirection: true,
          },
          tablet: {
            breakpoint: 0,
            smooth: true,
            getDirection: true,
          },
        }}
        watch={[]}
        containerRef={containerRef}
      >
        <Menu isIntro={isIntro} isDisabled={isMenuDisabled} />
        <Logo isIntro={isIntro} />
        <TestModal grain={selectedGrain} close={closeModal}  />
        <main data-scroll-container ref={containerRef}>
          <section data-scroll-section>
            <Frame />
            <section>
              {isIntro && !isRedirected && (
                <IntroModal
                  close={() => setIsIntro(false)}
                  closeModal={() => setIsIntro(false)}
                />
              )}

              {isRedirected && (
                <RedirectModal
                  close={() => {
                    closeModal();
                    setIsIntro(false);
                  }}
                />
              )}

              <ImageSection selectGrain={openModal} grains={allGrains} />
            </section>
            <IntroContainer />

            <VrContainer />

            <GrainsContainer />

            <Carousel />
            
            <EventsContainer />

            <AboutContainer />

            <PartnersContainer />

            <CreditsContainer />

            <Footer />

          </section>
        </main>
      </LocomotiveScrollProvider>
    </>
  );
}

export default App;
