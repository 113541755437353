import { useEffect, useState } from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";

const Logo = ({ isIntro }) => {
  const { scroll } = useLocomotiveScroll();
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    if (scroll) {
      scroll.on("scroll", (e) => {
        if (e.delta.y === e.limit.y) {
          setIsAtBottom(true);
        } else {
          setIsAtBottom(false);
        }
      });
    }
  }, [scroll]);

  const toTop = () => {
    const element = document.getElementById('image');
    scroll.scrollTo(element);
  }

  return (
    <div
      className={
        !isAtBottom && !isIntro ? "sticky-footer enabled" : "sticky-footer"
      }
    >
      <div className="logo-container" onClick={toTop}>
        <h1 className="logo">Last Grain of Sand</h1>
      </div>
    </div>
  );
};

export default Logo;
