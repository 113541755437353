import "../styles/policy.scss";

const TermsAndConditionsContainer = () => {
  return (
    <>
      <div className="justify-center-container mt-4">
        <div className="logo-container">
          <a href="/">
            <h1 className="logo">Last Grain of Sand</h1>
          </a>
        </div>
      </div>

      <section className="section-text policy-container">
        <h1>Terms and Conditions</h1>
        <em>Last updated 04/04/2022</em>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Definitions</strong>
        </p>
        <p>
          In these General Terms and Conditions the following terms shall have
          the following meanings, except where stated otherwise:
        </p>
        <p className="subhead">
          <strong>Consumer:</strong>
        </p>
        <p>
          the natural person who does not act for purposes related to his/her
          commercial, trade, craft or professional activities;
        </p>
        <p className="subhead">
          <strong>Day:</strong>
        </p>
        <p>calendar day;</p>
        <p className="subhead">
          <strong>Digital Content:</strong>
        </p>
        <p>data produced and delivered in digital form;</p>
        <p className="subhead">
          <strong>Distance Contract:</strong>
        </p>
        <p>
          a contract concluded by Post Neon and the Consumer within the scope of
          an organized system for distance selling products, Digital Content
          and/or services, whereby exclusive or additional use is made of one or
          more technologies of distance communication up to the conclusion of
          the contract;
        </p>
        <p className="subhead">
          <strong>Reflection Period:</strong>
        </p>
        <p>
          the period during which the Consumer may use his right of withdrawal;
        </p>
        <p className="subhead">
          <strong>Sustainable Data Carrier:</strong>
        </p>
        <p>
          any means, including email, that allow the Consumer or Post Neon to
          store information directed to him/her personally in such a manner that
          makes future consultation and use possible during a period that
          matches the purpose for which the information is destined and which
          makes unaltered reproduction of the stored information possible;
        </p>
        <p className="subhead">
          <strong>Standard Form for Withdrawal:</strong>
        </p>
        <p>the European standard form for withdrawal included in Appendix;</p>
        <p className="subhead">
          <strong>Post Neon</strong>
        </p>
        <p>
          the company Post Neon, located at Joris van Andringastraat 32H, in
          Amsterdam and registered with the Trade Register of the Dutch Chamber
          of Commerce under number 69482586; and
        </p>
        <p className="subhead">
          <strong>Website:</strong>
        </p>
        <p>
          name of the website of Post Neon, being&nbsp;
          <a className="linkt-c" href="https://lastgrainofsand.com/">
            lastgrainofsand.com
          </a>
          .
        </p>
        <p className="subhead">
          <strong>Applicability</strong>
        </p>
        <p>
          1. These General Terms and Conditions apply to any offer from Post
          Neon and to any Distance Contract concluded by Post Neon and the
          Consumer.
        </p>
        <p>
          2. Before concluding a Distance Contract, the text of these General
          Terms and Conditions shall be made available to the Consumer by
          electronic means in such a way that the Consumer can store it in an
          accessible manner on a Sustainable Data Carrier. If this is reasonably
          impossible, Post Neon shall indicate in what way the General Terms and
          Conditions can be inspected and that they will be sent if so requested
          by electronic means or otherwise without charge, before the Distance
          Contract is concluded.
        </p>
        <p>
          3. In the event that specific product or service conditions apply in
          addition to these General Terms and Conditions, the second paragraph
          of this Article shall apply accordingly, and in the event of
          contradictory terms and conditions, the Consumer may always appeal to
          the applicable provision that is most favourable to him/her.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>The offer</strong>
        </p>
        <p>
          1. Obvious errors or mistakes in the offer are not binding for Post
          Neon.
        </p>
        <p>
          2. All offers contain such information that it is clear to the
          Consumer what rights and obligations are attached to accepting the
          offer.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p className="subhead">
          <strong>The contract</strong>
        </p>
        <p>
          1. The contract becomes valid when the Consumer has accepted the offer
          and fulfilled the terms and conditions set.
        </p>
        <p>
          2. If the Consumer accepted the offer via electronic means, Post Neon
          shall promptly confirm receipt of having accepted the offer via
          electronic means.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Right of withdrawal</strong>
        </p>
        <p>
          1. The Consumer can repudiate a purchase contract for a product
          without giving reasons for a Reflection Period of 14 days.
        </p>
        <p>
          2. The Reflection Period referred to in paragraph 1 of this Article
          starts on the Day the product is received by the Consumer or by a
          third party appointed by him in advance and who is not the carrier,
          or:
        </p>
        <p>
          a. if the Consumer ordered several products in the same order: the Day
          on which the Consumer or a third party appointed by him received the
          last product.Post Neon may refuse an order of several products with
          different delivery dates provided that it clearly informs the Consumer
          prior to the order process, or
        </p>
        <p>
          b. in case the delivery of a product consists of several batches or
          parts: the Day on which the Consumer or a third party appointed by him
          received the last batch or the last part, or
        </p>
        <p>
          c. in case of an agreement about regular delivery of products during a
          given period: the Day on which the Consumer or a third party appointed
          by him received the first product.
        </p>
        <p>
          3. The Consumer can terminate an agreement for services or an
          agreement for delivery of Digital Content that is not delivered on a
          physical carrier without giving reasons during 14 dagen.
        </p>
        <p>
          4. The Reflection Period referred to in Article 3 starts on the Day
          following the conclusion of the agreement.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Consumer’s obligations during the Reflection Period</strong>
        </p>
        <p>
          1. During this period, the Consumer shall handle the product and the
          packaging with care. The Consumer shall only unpack or use the product
          to the extent necessary for establishing the nature, the
          characteristics and the effect of the product. The guiding principle
          is that the Consumer may only handle and inspect the product in the
          manner in which one is allowed to handle a product in a shop.
        </p>
        <p>
          2. The Consumer is liable for the decrease in value of the product
          that is caused by the way of handling the product which went further
          than allowed in paragraph 1 of this Article.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Exercising the consumer’s right of withdrawal</strong>
        </p>
        <p>
          1. If the Consumer exercises his right of withdrawal he shall notify
          Post Neon unambiguously with the Standard Form for Withdrawal within
          the Reflection Period.
        </p>
        <p>
          2. The Consumer shall return the product or deliver it to (the
          authorized representative of) Post Neon as soon as possible but within
          14 Days. This need not be done if Post Neon offered to collect the
          product himself. The Consumer observed the period of returning the
          product in any event if the product is returned before the expiration
          of the Reflection Period.
        </p>
        <p>
          3. The Consumer shall return the product with all delivered
          accessories and if reasonably possible in the original state and
          packing and in conformity with reasonable and clear instructions given
          by Post Neon.
        </p>
        <p>
          4. The risk and the burden of proof for the correct and timely
          exercise of the right of withdrawal fall on the Consumer.
        </p>
        <p>
          5. The Consumer shall bear the direct costs of returning the product.
        </p>
        <p>
          6. If performance of the service has been started during the
          Reflection Period, the Consumer shall pay Post Neon an amount that is
          equal to the part of the obligation already performed at the time of
          withdrawal as compared with the full compliance of the obligation.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Obligations of Post Neon in case of withdrawal</strong>
        </p>
        <p>
          1. If Post Neon makes the notification of withdrawal by electronic
          means possible, he shall promptly send a return receipt.
        </p>
        <p>
          2. Post Neon shall reimburse all payments made by the Consumer,
          including any delivery costs that Post Neon may charge for the
          returned product, as soon as possible but within 14 Days following the
          Day on which the Consumer notified him of the withdrawal. Unless Post
          Neon offers to collect the product himself, he can wait with paying
          back until having received the product or until the Consumer proved
          that he returned the product, whichever occurs first.
        </p>
        <p>
          3. Post Neon shall make use of the same means of payment that the
          Consumer used, unless the Consumer consents to another method. The
          reimbursement is free of charge for the Consumer.
        </p>
        <p>
          4. If the Consumer opted for a more expensive method of delivery
          instead of the cheapest standard delivery, Post Neon need not
          reimburse the additional costs for the more expensive method.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Pricing</strong>
        </p>
        <p>
          1. The prices of the products and/or services provided shall not be
          raised during the validity period given in the offer, subject to
          changes in price due to changes in VAT rates.
        </p>
        <p>
          2. Contrary to paragraph 1 of this Article, Post Neon may offer
          products or services whose prices are subject to fluctuations in the
          financial market that are beyond the control of Post Neon, at variable
          prices. The offer will state the possibility of being subject to
          fluctuations and the fact that any indicated prices are target prices.
        </p>
        <p>
          3. Price increases within 3 months after concluding the contract are
          permitted only if they are the result of new legislation.
        </p>
        <p>
          4. Price increases from 3 months after concluding the contract are
          permitted only if Post Neon has stipulated it and
        </p>
        <p>a. they are the result of legal regulations or stipulations, or</p>
        <p>
          b. the Consumer has the authority to cancel the contract before the
          Day on
        </p>
        <p>which the price increase starts.</p>
        <p>
          5. All prices indicated in the provision of products or services are
          including VAT.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Performance agreement and extra guarantee</strong>
        </p>
        <p>
          1. Post Neon guarantees that the products and/or services comply with
          the contract, with the specifications listed in the offer, with
          reasonable requirements of usability and/or reliability and with the
          existing statutory provisions and/or government regulations on the Day
          the contract was concluded. If agreed, Post Neon also guarantees that
          the product is suitable for other than normal use.
        </p>
        <p>
          2. In any event no claim can be made on paragraph 1 of this Article
          (i) in case of damage caused deliberately or with gross negligence,
          (ii) in case of normal wear and/or (iii) in case of damage from
          improper care or handling.
        </p>
        <p>
          3. An extra guarantee offered by Post Neon, his supplier, manufacturer
          or importer shall never affect the rights and claims the Consumer may
          exercise against Post Neon about a failure in the fulfilment of the
          obligations of Post Neon if Post Neon has failed in the fulfilment of
          his part of the agreement.
        </p>
        <p>
          4. ‘Extra guarantee’ is taken to mean each obligation by Post Neon,
          his supplier,
        </p>
        <p>
          importer or manufacturer in whom he assigns certain rights or claims
          to the Consumer that go further than he is legally required in case he
          fails in the compliance with his part of the agreement.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Delivery and execution agreement</strong>
        </p>
        <p>
          1. Post Neon shall exercise the best possible care when booking orders
          and executing product orders and when assessing requests for the
          provision of services.
        </p>
        <p>
          2. The place of delivery is the address which the Consumer has
          provided to Post Neon
        </p>
        <p>
          3. The Consumer shall provide Post Neon with the correct delivery
          address and mail address. Any changes need to be reported immediately
          to Post Neon. If the Consumer has entered a wrong address for
          delivery, then the additional shipping costs will be for the account
          of the Consumer.
        </p>
        <p>4. Delivery takes place while supply lasts.</p>
        <p>
          5. The ownership of delivered products will be transferred to the
          Consumer upon payment of the sum due. The risk of the products passes
          upon delivery to the Consumer.
        </p>
        <p>
          6. Post Neon shall execute accepted orders with convenient speed but
          at least within 30 days, unless another delivery period was agreed on.
          If the delivery has been delayed, or if an order cannot be filled or
          can be filled only partially, the Consumer shall be informed about
          this within 30 dagen after ordering. In such cases, the Consumer is
          entitled to repudiate the contract free of charge and with the right
          to possible compensation.
        </p>
        <p>
          7. After repudiation in conformity with paragraph 6 of this Article,
          Post Neon shall promptly return the payment made by the Consumer.
        </p>
        <p>
          8. The risk of loss and/or damage to products will be borne by Post
          Neon until the time of delivery to the Consumer or a representative
          appointed in advance and made known to Post Neon, unless explicitly
          agreed otherwise.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Payment</strong>
        </p>
        <p>
          1. Unless otherwise stipulated in the agreement or in the additional
          conditions, the amounts to be paid by the Consumer must be settled
          within 14 Days after the Reflection Period, or, if there is no
          Reflection Period, within 14 Days, after concluding the agreement. In
          case of an agreement to provide a service, this period starts on the
          Day that the Consumer received the confirmation of the agreement.
        </p>
        <p>
          2. The Consumer has the duty to inform Post Neon promptly of possible
          inaccuracies in the payment details that were given or specified.
          Payment orders at the bank or giro institute are for the account and
          risk of the party giving (or authorizing) the payment order.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Accounts and registration</strong>
        </p>
        <p>
          1. The Consumer can create an account or otherwise register on the
          Website. Post Neon reserves the right to refuse or cancel any such
          registration, for example in the event of irregularities.
        </p>
        <p>
          2. The login details are strictly personal and must not be disclosed
          to third parties. The Consumer is responsible for his login details,
          even if this is done without his knowledge.
        </p>
        <p>
          3. The Consumer shall immediately alert Post Neon if he suspects that
          his login details are known by an unauthorized third party or in the
          event of irregularities.
        </p>
        <p>
          4. The Consumer may not create or manage more than one account.
          Moreover, the Consumer shall not be allowed to register or manage an
          account (again) after Post Neon has denied the request of the Consumer
          to create an account or after cancellation of the registration.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Force majeure</strong>
        </p>
        <p>
          Post Neon and the Consumer are not obliged to fulfill any obligation,
          if they are prevented from doing so due to a circumstance not
          attributable to fault and which is not regarded as their
          responsibility, nor by virtue of the law, a legal action or a
          generally accepted notion.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Complaints procedure</strong>
        </p>
        <p>
          1. Post Neon shall have a sufficiently notified complaints procedure
          in place, and shall handle the complaint in accordance with this
          complaint procedure.
        </p>
        <p>
          2. Complaints about the performance of contracts shall be filed as
          soon as possible once the Consumer has noticed the defects,
          comprehensive and clear with Post Neon.
        </p>
        <p>
          3. The complaints submitted to Post Neon shall be replied within a
          period of 7 dagen after the date of receipt. Should a complaint
          require a foreseeable longer time of handling, Post Neon shall respond
          within 7 dagen with a notice of receipt and an indication when the
          Consumer can expect a more detailed reply.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Privacy and security</strong>
        </p>
        <p>
          1. Post Neon respects the privacy of the Consumer. Post Neon treats
          and processes all personal data transmitted to it in accordance with
          the applicable legislation, in
        </p>
        <p>
          particular the Personal Data Protection Act. The Consumer agrees to
          this processing. For the
        </p>
        <p>
          purpose of protecting the Consumer’s personal data, Post Neon observes
        </p>
        <p>appropriate security measures.</p>
        <p>
          2. For more information about privacy, reference is made to the
          Website of Post Neon.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Additional provisions</strong>
        </p>
        <p>
          1. Any possible derogations in respect of these General Terms and
          Conditions may be agreed upon only in writing. No rights can be
          derived from these deviations with regard to future agreements.
        </p>
        <p>
          2. The administration of Post Neon serves as proof of purchase orders
          and requests of the Consumer, unless the Consumer proves otherwise.
          The Consumer acknowledges that electronic communication can serve as
          proof.
        </p>
        <p>
          3. Post Neon is entitled to transfer the rights and obligations under
          the contract with the Consumer to a third party, by a single
          notification to the Consumer.
        </p>
        <p>
          4. In case and to the extent that any provisions of these General
          Terms and Conditions are cancelled or declared invalid, Post Neon
          shall draw up a new provision to replace the null/nullified provision,
          duly observing as much as possible the object and purpose of the
          annulled provision.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Applicable law</strong>
        </p>
        <p>
          These General Terms and Conditions, and any non-contractual
          obligations arising thereto, between Post Neon and the Consumer are
          governed exclusively by the laws of The Netherlands.
        </p>
        <p>&nbsp;</p>
        <p className="subhead">
          <strong>Appendix</strong>
        </p>
        <p>Standard Form for Withdrawal</p>
        <p>To: Post Neon, Overhoeksplein 2, domiciling at Amsterdam.</p>
        <p>Email address: info@post-neon.com</p>
        <p>
          I hereby inform you that I wish to revoke our agreement on the
          following order:
        </p>
        <p>Order number:</p>
        <p>Ordered/received on (dd-mm-yyyy):</p>
        <p>
          Fill in the date on which you received your order or the date of your
          order.
        </p>
        <p>First name and surname:</p>
        <p>Street name + house number:</p>
        <p>ZIP code:</p>
        <p>Place of domicile:</p>
        <p>Email address:</p>
        <p>General Terms and Conditions 5</p>
        <p>Date:</p>
        <p>Signature</p>
        <p>&nbsp;</p>
        <p>
          Sign this form only if you will not send this form by e-mail or
          ordinary post.
        </p>
      </section>
    </>
  );
};

export default TermsAndConditionsContainer;
