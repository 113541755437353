import "../styles/grainsContainer.scss";
import grainObsidian from "../assets/img/grains/grain_obsidian.png";
import grainQuartz from "../assets/img/grains/grain_quartz.png";
import grainMasonry from "../assets/img/grains/grain_masonry.png";
import grainSilica from "../assets/img/grains/grain_silica.png";
import Divider from "../components/Divider";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import * as gsap from "gsap";

const GrainsContainer = () => {
  const { scroll } = useLocomotiveScroll();

  const scrollToImage = () => {
    const element = document.getElementById("image");
    scroll.scrollTo(element);
  };

  const fadeGrain = (grainId) => {
    gsap.default.to(grainId, { opacity: 0, duration: 1 });
  };

  const showGrain = (grainId) => {
    gsap.default.to(grainId, { opacity: 1, duration: 1 });
  };

  return (
    <>
      <section
        id="grains"
        className="section-text"
      >
        <div className="columns">
          <div className="column is-5">
            <h1 className="title">Virtual Grains</h1>
          </div>
          <div className="column content">
            <p>
              Since the Genesis of glass, we have come to utilise a wide variety
              of sand types in an extreme amount of groundbreaking applications.
              From the invention of concrete to microchips on a nanoscopic
              scale, all sorts of sand types have become essential to most of
              our daily lives and surroundings. Without sand, we would have no
              phones, no cars, no cosmetics, no buildings… Post Neon, and its
              partnering team, have virtually recreated four sand types that are
              part of the overwhelming collective library of sands used
              throughout industrial and commercial engineering.{" "}
            </p>

            <p>
              <a onClick={scrollToImage}>
                Collect your own unique sand grain and contribute
              </a>{" "}
              to the collective awareness of the scarcity of sand by sharing the
              story of your own grain with your friends, family and colleagues.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="columns is-multiline">
          {/* Obsidian */}
          <div
            onMouseEnter={() => fadeGrain("#quartz")}
            onMouseLeave={() => showGrain("#quartz")}
            className="column is-half grain-tile-column"
          >
            <div id="obsidian" className="grain-tile left-tile">
              <div className="bg"></div>
              <h1 className="title">
                <span className="name">Obsidian | <span className="chapter-name">Genesis</span></span>
                <span className="number">1</span>
              </h1>
              <div className="img-container">
                <img
                  data-scroll
                  data-scroll-speed="2"
                  src={grainObsidian}
                  alt="A grain of obsidian"
                />
              </div>

              <div className="overlay">
                <p className="text">
                  Our history with sand begins with the accidental discovery of
                  the origins of glass through a natural phenomenon. The dark
                  mystical glass rock Obsidian is naturally formed when lava,
                  rich in light elements like Silicon, Oxygen, Aluminum, Sodium
                  and Potassium, rapidly cools with minimal crystal growth. In
                  its natural state, a so-called Obsidian lava flow oozes over a
                  pre-existing landscape, turning green lands into blackened
                  moonscapes dramatised by nothing but shimmering razored rocks
                  and boulders. Ever since its discovery as a resource, Obsidian
                  glass has been used as weaponry, ornaments, tools and even
                  mirrors, due to its sharp edges and reflective qualities.
                </p>
              </div>
            </div>
          </div>

          {/* Quartz */}
          <div
            onMouseEnter={() => fadeGrain("#obsidian")}
            onMouseLeave={() => showGrain("#obsidian")}
            className="column grain-tile-column"
          >
            <div id="quartz" className="grain-tile title-bottom right-tile">
              <div className="bg"></div>

              <div className="img-container">
                <img
                  data-scroll
                  data-scroll-speed="5"
                  className="image-top"
                  src={grainQuartz}
                  alt="A grain of quartz"
                />
              </div>

              <h1 className="title bottom">
                <span className="name">Quartz | <span className="chapter-name">Human Alchemy</span></span>
                <span className="number">2</span>
              </h1>

              <div className="overlay text-opposite">
                <p className="text text-top">
                  Nearly all of us are familiar with Quartz, not because it is
                  one of the most common sand types, but for the simple fact
                  that it makes up a large majority of the world’s beaches. This
                  physically and chemically heard wearing mineral, represents
                  the second chapter in the untold narrative of sand as it
                  exhibits the diverse range of tools that man created
                  throughout history. Ceramics, metal castings, sand paper,
                  water filtration, paints and golf courses are all but a
                  fraction of examples that would not exist without the use of
                  Quartz.
                </p>
              </div>
            </div>
          </div>

          {/* Masonry */}
          <div
            onMouseEnter={() => fadeGrain("#silica")}
            onMouseLeave={() => showGrain("#silica")}
            className="column is-half grain-tile-column"
          >
            <div id="masonry" className="grain-tile">
              <div className="bg"></div>

              <h1 className="title">
                <span className="name">Masonry | <span className="chapter-name">Greed</span></span>
                <span className="number">3</span>
              </h1>
              <div className="img-container">
                <img
                  data-scroll
                  data-scroll-speed="8"
                  src={grainMasonry}
                  alt="A grain of masonry"
                />
              </div>

              <div className="overlay">
                <p className="text">
                  Mason sand, also known as masonry sand or mortar sand,
                  surrounds us nearly everyday as we pass through cities, roads
                  and houses. The fine, soft substance is often used in
                  construction. Due to our ever increasing need of expansion,
                  the resources of sand from the bottoms of our sea's and large
                  quantities of quarry rock are permanently entombed in concrete
                  structures never to be freed again. Due to its application to
                  literally build our world, masonry represents the chapter of
                  greed within a Last Grain of Sand. Its depletion has
                  irreversible impacts on our ecological living environment.
                </p>
              </div>
            </div>
          </div>

          {/* Silica */}
          <div
            onMouseEnter={() => fadeGrain("#masonry")}
            onMouseLeave={() => showGrain("#masonry")}
            className="column grain-tile-column"
          >
            <div id="silica" className="grain-tile title-bottom">
              <div className="bg"></div>

              <div className="img-container">
                <img
                  data-scroll
                  data-scroll-speed="4"
                  className="image-top"
                  src={grainSilica}
                  alt="A grain of silica"
                />
              </div>

              <h1 className="title bottom">
                <span className="name">Silica | <span className="chapter-name">Anthropocene </span></span>
                <span className="number">4</span>
              </h1>

              <div className="overlay text-opposite">
                <p className="text text-top">
                  Silica can be found all over the world in a breadth of shapes
                  and sizes. The white beaches of Siesta Key in Florida are a
                  famous example of silica sand, whilst living microbes such as
                  algae and radiolarians have siliceous shells. This mineral
                  sand type is made up of over 95% silicon dioxide (SiO2) and
                  0,6% irondioxide. Silica is specifically mined and processed
                  for its chemical stability making it an essential part in the
                  manufacturing of products such as glass, computer chips, fiber
                  optics, solar panels, rubbers and pharmaceuticals. The
                  artificial, manufactured characteristic of such technologies
                  and products obscures the use of natural resources. Silica
                  exemplifies the current lost connection we have with sand, and
                  thus represents the chapter of the anthropocene in the unknown
                  narrative of sand.
                </p>
              </div>
            </div>
          </div>

          <Divider />
        </div>
      </section>
    </>
  );
};

export default GrainsContainer;
