import React, { Suspense, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { Instances, Instance, useGLTF, Html } from "@react-three/drei";
import Tile from "./Tile";
import { useLocomotiveScroll } from "react-locomotive-scroll";

const rightAngle = Math.PI / 2;

const ImageSection = React.memo(({ selectGrain, grains }) => {
  const pointLight = useRef();
  const { scroll } = useLocomotiveScroll();

  const setPosition = (e) => {
    e.stopPropagation();

    const x = e.pageX - window.innerWidth / 2;
    const y = -(e.pageY - window.innerHeight / 2);
    const distance = -250;

    pointLight.current.position.set(x, y, distance);
  };

  const createPositions = () => {
    const firstRow = [];
    const xDif = 82;
    const yDif = 83;

    for (let i = 1; i <= 8; i++) {
      for (let j = 1; j <= 5; j++) {
        const position = [-380 + i * xDif, 200 - j * yDif, -200];
        firstRow.push({ position: position });
      }
    }

    return firstRow;
  };

  const Panels = ({ onUpdatePointLight }) => {
    const positions = createPositions();
    const { nodes, materials } = useGLTF("/tegel3.gltf");
    return (
      <Instances
        range={100}
        material={materials["171221_Arno_3D_human_alchemy"]}
        geometry={nodes["171221_Arno_3D_human_alchemy"].geometry}
      >
        {positions.map((props, i) => (
          <Panel key={i} {...props} onMouseOver={onUpdatePointLight} />
        ))}
      </Instances>
    );
  };

  const Panel = ({ random, ...props }) => {
    const ref = useRef();
    return (
      <group {...props}>
        <Instance
          ref={ref}
          position={props.position}
          rotation={[rightAngle, 0, 0]}
          onPointerMove={props.onMouseOver}
        />
      </group>
    );
  };

  const openModal = async (id) => {
    scroll.stop();
    selectGrain(id);
  };

  const Tiles = () => {
    const tiles = [];
    for (let i = 1; i < 25; i++) {
      for (let j = 1; j < 44; j++) {
        const imageName = `row-${i}-column-${j}`;

        const grain = grains.find((g) => g.row === i && g.column === j);

        tiles.push(
          <Tile
            key={imageName}
            grain={grain}
            imageName={imageName}
            selectGrain={() => openModal(grain.grainId)}
          />
        );
      }
    }

    return tiles;
  };

  return (
    <div id="image" className="overflow-container">
      <Canvas
        style={{
          height: "1080px",
          width: "1920px",
          maxHeight: "1080px",
          maxWidth: "1920px",
        }}
      >
        <Suspense fallback={null}>
          <Panels onUpdatePointLight={setPosition} />

          <pointLight
            ref={pointLight}
            position={[15, 13, -395]}
            intensity={0.3}
          />

          <Html fullscreen={true}>
            <div className="shadow-horizontal"></div>
            <div className="shadow-vertical"></div>

            <div className="grid" onMouseMove={setPosition}>
              {grains.length > 0 && <Tiles />}
            </div>
          </Html>
        </Suspense>
      </Canvas>
    </div>
  );
});

export default ImageSection;
