import "../../styles/modal.scss";
import { IoCloseOutline } from "react-icons/io5";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import * as gsap from "gsap";
import { useEffect, useState } from "react";

const RedirectModal = ({ close }) => {
  const [isPayment, setIsPayment] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const { scroll } = useLocomotiveScroll();

  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (scroll) {
      scroll.stop();
      setIsPayment(params.get("payment") === "true");
    }
  }, [scroll]);

  const closeModal = async () => {
    gsap.default.to(".redirect-modal", { autoAlpha: 0, duration: 0.1 });
    await gsap.default.to(".modal-bg", { autoAlpha: 0, duration: 0.6 });

    close();
    setIsOpen(false);
    scroll.start();
  };

  return (
    <>
      {isOpen && (
        <>
          <div className="modal-bg active"></div>
          <div className="justify-center-container">
            <div className="modal redirect-modal active">
              <IoCloseOutline className="close-icon" onClick={closeModal} />
              <h1 className="title">Thank you for participating</h1>
              {isPayment && (
                <p>
                  If your payment was succesful you will receive an email with
                  your grain shortly.
                </p>
              )}

              {!isPayment && (
                <p>You will receive an email with your grain shortly.</p>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RedirectModal;
