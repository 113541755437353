import mu from '../assets/img/partners/MU.png';
import fontys from '../assets/img/partners/fontys.png';
import strp from '../assets/img/partners/STRP_logoStaand_wit.png';
import fonds from '../assets/img/partners/SCI_Woordbeeld_EN_2_regels_RGB_White.gif';
import fiber from '../assets/img/partners/FIBER-logo.svg';
import atelier from '../assets/img/partners/logo-atelier.svg';


const PartnersContainer = () => {
  return (
    <section className="section-text  section-partners">
        <h1 className="title">
            Partners
        </h1>

        <div className="columns is-multiline partner-columns is-centered is-vcentered">
            <div className="column is-5">
                <img src={mu} alt="MU" />
            </div>
            <div className="column is-6">
                <img src={fontys} alt="Fontys Hogeschool Journalistiek" />
            </div>
            <div className="column is-2">
                <img src={strp} alt="STRP" />
            </div>
            <div className="column">
                <img src={fonds} alt="Creative Industries Fund" />
            </div>
            <div className="column">
                <img src={fiber} alt="FIBER" />
            </div>
            <div className="column">
                <img style={{ filter: 'brightness(0) invert(1)' }} src={atelier} alt="Atelier NL" />
            </div>
        </div>
    </section>
  )
}

export default PartnersContainer