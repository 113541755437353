import { useState } from "react";
import ReactPlayer from "react-player/lazy";
import "../styles/video.scss";
import thumb from "../assets/img/thumb.webp";

const VideoContainer = ({ id = "video", url, playText }) => {
  const [videoSettings, setVideoSettings] = useState({
    isPlaying: false,
    isFirstPlay: true,
  });

  return (
    // <div style={{ display: "block" }} className="is-block">
    //   <section id={id}>
    //     <div
    //       className="video-container"
    //       onClick={() => setVideoSettings({isPlaying: !videoSettings.isPlaying, isFirstPlay: false})}
    //     >
    //       {videoSettings.isFirstPlay && <div className="thumbnail is-absolute"></div>}
    //       {!videoSettings.isPlaying && (
    //         <>
    //           <button
    //             className="button is-absolute"
    //             onClick={() => {
    //               setVideoSettings({isFirstPlay: false, isPlaying: true})
    //             }}
    //           >
    //             {playText}
    //           </button>
    //         </>
    //       )}
    //     </div>
    //   </section>
    // </div>

    <div className="border-container" onClick={() => {
      setVideoSettings({ isFirstPlay: false, isPlaying: !videoSettings.isPlaying });
    }}>
      <div className="video-overlay"
        style={!videoSettings.isFirstPlay ? { background: 'none'} : null}
      >
      {!videoSettings.isPlaying && <button
          className="button"
          onClick={() => {
            setVideoSettings({ isFirstPlay: false, isPlaying: true });
          }}
        >
          Play Teaser
        </button> }
      </div>

      <ReactPlayer
        url={url}
        playing={videoSettings.isPlaying}
        height={"100%"}
        width={"100vw"}
        loop={true}
      ></ReactPlayer>
    </div>
  );
};

export default VideoContainer;
