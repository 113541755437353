import preview from "../assets/img/vr_greed.jpg";

const VrContainer = () => {
  return (
    <section id="vr" className="section-text ">
      {/* VR */}
      <div className="columns">
        <div className="column is-5">
          <h1 className="title">Virtual Reality</h1>
        </div>
        <div className="column content">
          <p>
            In this fully immersive experience, space and time morph as Michael
            Welland's voice narrates the history and future of our relationship
            with sand. A set of five chapters{" "}
            Genesis, Human Alchemy, Greed, Anthropocene and{" "}
            Natural Relic lead through imaginary landscapes, past
            colossal floating sands grains and dredgers roaming the deep sea.
            Discover how a seemingly mundane material would go from a highly
            regarded engineering component to a forgotten resource that we are
            now running out of.
          </p>

          <img
            style={{ width: "100%" }}
            src={preview}
            alt="Preview of the VR experience."
          />
        </div>
      </div>
    </section>
  );
};

export default VrContainer;
