const CookiePolicyContainer = () => {
  return (
    <>
      <div className="justify-center-container mt-4">
        <div className="logo-container">
          <a href="/">
            <h1 className="logo">Last Grain of Sand</h1>
          </a>
        </div>
      </div>
      <section className="section-text policy-container">
        <h1>Cookie Policy</h1>
        Last updated 18/03/2022
        <p>
          This Cookie Policy explains how Post Neon ("Company,” "we,” "us,” and
          "our"), uses cookies and similar technologies to recognize you when
          you visit our websites at www.lastgrainofsand.com ("Website"). It
          explains what these technologies are and why we use them, as well as
          your rights to control our use of them. In some cases we may use
          cookies to collect personal information, or that becomes personal
          information if we combine it with other information.{" "}
        </p>
        <strong>What are cookies?</strong>
        <p>
          Cookies are small data files that are placed on your computer or
          mobile device when you visit a website. Cookies are widely used by
          website owners in order to make their websites work, or to work more
          efficiently, as well as to provide reporting information. Cookies set
          by the website owner (in this case, Post Neon are called "first party
          cookies". Cookies set by parties other than the website owner are
          called "third party cookies". Third party cookies enable third party
          features or functionality to be provided on or through the website
          (e.g. like advertising, interactive content and analytics). The
          parties that set these third party cookies can recognize your computer
          both when it visits the website in question and also when it visits
          certain other websites.
        </p>
        <strong>Why do we use cookies?</strong>
        <p>
          We use first and third party cookies for several reasons. Some cookies
          are required for technical reasons in order for our Website to
          operate, and we refer to these as "essential" or "strictly necessary"
          cookies. Other cookies also enable us to track and target the
          interests of our users to enhance the experience on our Online
          Properties. Third parties serve cookies through our Websites for
          analytics and other purposes. This is described in more detail below.
          The specific types of first and third party cookies served through our
          Websites and the purposes they perform are described below (please
          note that the specific cookies served may vary depending on the
          specific Online Properties you visit):
        </p>
        <strong>How can I control cookies?</strong>
        <p>
          You have the right to decide whether to accept or reject cookies. You
          can exercise your cookie rights by setting your preferences in the
          notification banner that gets presented to you upon your first time
          visiting the website. Essential cookies cannot be rejected as they are
          strictly necessary to provide you with services. If you choose to
          reject cookies, you may still use our website though your access to
          some functionality and areas of our website may be restricted. You may
          also set or amend your web browser controls to accept or refuse
          cookies. As the means by which you can refuse cookies through your web
          browser controls vary from browser-to-browser, you should visit your
          browser's help menu for more information.
        </p>
        <p>
          In addition, most advertising networks offer you a way to opt out of
          targeted advertising. If you would like to find out more information,
          please visit{" "}
          <a
            href="http://www.aboutads.info/choices/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.aboutads.info/choices/
          </a>{" "}
          or
          <a
            href="http://www.youronlinechoices.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.youronlinechoices.com
          </a>
          .
        </p>
        <p>
          The specific types of first and third party cookies served through our
          Website and the purposes they perform are described in the table below
          (please note that the specific cookies served may vary depending on
          the specific Online Properties you visit):
        </p>
        <strong>Essential website cookies:</strong>
        <p>
          These cookies are strictly necessary to provide you with services
          available through our Website and to use some of its features.
        </p>
        <div className="justify-center-container">
          <table className="tg">
            <tbody>
              <tr>
                <th className="tg-0lax">Name:</th>
                <th className="tg-0lax">
                  <span>
                    <em>analyticsEnabled</em>
                  </span>
                </th>
              </tr>
              <tr>
                <td className="tg-0lax">Purpose:</td>
                <td className="tg-0lax">
                  Used to remember your cookie preferences.
                </td>
              </tr>
              <tr>
                <td className="tg-0lax">Provider:</td>
                <td className="tg-0lax">This domain</td>
              </tr>
              <tr>
                <td className="tg-0lax">Expires in:</td>
                <td className="tg-0lax">2 years</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <strong>Analytics and customization cookies:</strong>
        <p>
          These cookies collect information that is used either in aggregate
          form to help us understand how our Websites are being used or how
          effective our marketing campaigns are, or to help us customize our
          Websites for you.
        </p>
        <div className="justify-center-container">
          <table className="tg">
            <tbody>
              <tr>
                <th className="tg-0lax">Name:</th>
                <th className="tg-0lax">
                  <span>
                    <em>_ga</em>
                  </span>
                </th>
              </tr>
              <tr>
                <td className="tg-0lax">Purpose:</td>
                <td className="tg-0lax">
                  Google Analytics used to identify unique users. <br /> Please
                  refer to Google's privacy policy for <br /> more details:
                  <a
                    href="https://policies.google.com/technologies/cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://policies.google.com/technologies/cookies
                  </a>
                  .
                </td>
              </tr>
              <tr>
                <td className="tg-0lax">Provider:</td>
                <td className="tg-0lax">Google</td>
              </tr>
              <tr>
                <td className="tg-0lax">Expires in:</td>
                <td className="tg-0lax">2 years</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <strong>How often will you update this Cookie Policy?</strong>
        <p>
          We may update this Cookie Policy from time to time in order to
          reflect, for example, changes to the cookies we use or for other
          operational, legal or regulatory reasons. Please therefore re-visit
          this Cookie Policy regularly to stay informed about our use of cookies
          and related technologies. The date at the top of this Cookie Policy
          indicates when it was last updated.
        </p>
        <strong>How often will you update this Cookie Policy?</strong>
        <p>
          If you have any questions about our use of cookies or other
          technologies, please email us at{" "}
          <a href="mailto:info@post-neon.com">info@post-neon.com</a>.
        </p>
      </section>
    </>
  );
};

export default CookiePolicyContainer;
