import VideoContainer from "./VideoContainer";

const IntroContainer = () => {
  return (
    <>
      <VideoContainer
        url={"https://post-neon.com/last_grain_of_sand/teaser2.webm"}
        playText={"Play Teaser"}
      />
      <section id="about" className="section-text">
        <div className="columns">
          <div className="column is-5">
            <h1 className="title">About</h1>
          </div>
          <div className="column content">
            <p>
              Most of us are unfamiliar with the idea that our daily lives
              highly depend on sand. Equally, most of us are unaware with the
              idea that sand is running out. A material often associated with
              sweltering deserts, tropical beaches, and the depths of the oceans
              in fact plays an essential role in our daily lives. Nearly
              everything we depend on can be deducted from a grain of sand.
              Cemented mega structures, mobile devices, cosmetics,
              pharmaceuticals as well as rubber tires, water filtration systems,
              surgical steel, and mirrors are all but a fraction of things that
              wouldn't exist without the granular material. Centuries of ever
              increasing expansion and constant discovery of nuanced forms of
              industrial applications have entirely abstracted our relationship
              with this natural finite resource.
              <br />
              <br />A Last Grain of Sand is a virtual exploration of this
              unknown narrative of sand. Through a VR experience and a Virtual
              Campaign an imaginary journey unfolds that details the history,
              present, and future of a forgotten relationship with a finite
              resource we are highly dependent on. Inspired by recordings of the
              philosophical testament of geologist Michael Welland, Post Neon
              and its creative partners created an immersive story around the
              final lecture of Welland at the symposium of Atelier NL and MU
              HybridArt House: the Abundance and Scarcity of Sand.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntroContainer;
