import CreditColumn from "../components/CreditColumn";

const CreditsContainer = () => {
  return (
    <section className="section-text section-partners">
      <h1 className="title">Credits</h1>

      <div className="columns is-multiline">
        <CreditColumn
          title={"Campaign, Website and Type Design"}
          name={"Charlotte Rohde and Hannes Brischke"}
          link={'http://charlotterohde.de/'}
        />

        <CreditColumn title={"Campaign and 3D"} name={"Niels Moolenaar"} link={'https://www.linkedin.com/in/niels-moolenaar-b3693695/'} />

        <CreditColumn
          title={"Physical Installation and Pattern Design"}
          name={"Arno Hoogland"}
          link={'https://arnohoogland.com/'}
        />

        <CreditColumn title={"Website Development"} name={"Paul Evers"} link={'https://www.linkedin.com/in/paul-evers/'} />

        <CreditColumn title={"Copywrite"} name={"Matthijs Loriaux"} link={'https://matthijsloriaux.com/'}/>

        <CreditColumn title={"VR Assistance"} name={"Ali Eslami"} link={'https://alllesss.com/'} />

        <CreditColumn
          title={"Sound Design"}
          name={"Pim Boreel"}
          link={'https://pimboreel.com/'}
        />

        <CreditColumn
          title={"Concept, Creative Direction and VR"}
          name={"Post Neon"}
          link={'https://post-neon.com/'}
        />

      </div>
    </section>
  );
};

export default CreditsContainer;
