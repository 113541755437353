import "../styles/carousel.scss";
import Marquee from "react-fast-marquee";

import grain1 from "../assets/img/grains/OBSIDIAN_lowres_0032.jpg";
import grain2 from "../assets/img/grains/OBSIDIAN_lowres_0076.jpg";
import grain3 from "../assets/img/grains/QUARTZ_lowres_0538.jpg";
import grain4 from "../assets/img/grains/QUARTZ_lowres_1914.jpg";
import grain5 from "../assets/img/grains/MASONRY_lowres_0388.jpg";
import grain6 from "../assets/img/grains/MASONRY_lowres_1653.jpg";
import grain7 from "../assets/img/grains/SILICA_lowres_1311.jpg";
import grain8 from "../assets/img/grains/SILICA_lowres_1998.jpg";
import { useLocomotiveScroll } from "react-locomotive-scroll";

const Carousel = () => {
  const { scroll } = useLocomotiveScroll();


  const toTop = () => {
    const element = document.getElementById('image');
    scroll.scrollTo(element);
  }


  return (
    <section className="section-carousel">
      <div className="carousel-text-wrapper">
      <div className="section-text carousel-text">
        <p className="medium-font">Be part of the story of sand. Choose a grain and claim it as yours.</p>
        <button className="button" onClick={toTop}>Collect a grain</button>
      </div>

      </div>
      <Marquee speed={40}>
        <img src={grain1} />
        <img src={grain3} />
        <img src={grain5} />
        <img src={grain7} />


        <img src={grain2} />
        <img src={grain4} />
        <img src={grain6} />
        <img src={grain8} />
      </Marquee>
    </section>
  );
};

export default Carousel;
